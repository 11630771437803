@import "../../Styles/Colors/color_variables.scss";

.pImage {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin: 27px;
}
.row-popup {
  display: flex;
  height: 200px;
}
.column-popup {
  flex: 50%;
}
.divider2 {
  border: 1px solid $secondary-color-7;
}

.column-popup {
  .upload-button {
    border-radius: 8px;
    margin: 36px 0px 8px 0px;
    background: $primary-color-2;
    text-transform: initial;
    width: 145px;
    height: 40px;
    font-family: PoppinsSemiBold, sans-serif !important;
    font-size: 0.875rem !important;
    color: $secondary-color-22;
  }
  .remove-button {
    display: flex;
    position: static;
    width: 145px;
    text-transform: initial;
    border: 2px solid $primary-color-2;
    border-radius: 8px;
    font-family: PoppinsSemiBold, sans-serif !important;
    color: $primary-color-2;
  }
  .avatar {
    margin-left: 71px;
    margin-top: 36px;
  }
  .btn:hover {
    background-image: none;
    background-color: $primary-color-2;
  }
}
.user-profile-popper {
  padding: 10px;
  width: 242px;
  left: -2% !important;
  top: 1px !important;
  z-index: 1300;
  font-size: 0.75rem;
  background: $secondary-color-22;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  font-family: Poppins, sans-serif;
  border-radius: 8px;
}
.popper-image-align {
  height: 15px;
  width: 15px;
  margin-right: 10px;
  margin-top: 2px;
}
.popper-image-align-mobile {
  height: 19px;
  width: 12px;
  margin-right: 10px;
  margin-top: 2px;
}
.popper-image-align-details {
  height: 15px;
  width: 15px;
  margin-right: 10px;
  margin-top: 2px;
}
.popper-image-align-logout {
  height: 10px;
  width: 10px;
  margin-left: 6px;
  margin-top: 2px;
}
.popper-div-align {
  padding: 5px 0;
  display: flex;
  justify-content: flex-start;
}
.popper-div-align-end {
  padding: 5px;
  display: flex;
  justify-content: flex-end;
}
.cursor-change {
  cursor: pointer;
}
.divider-style {
  position: static;
  left: 0%;
  right: 0%;
  top: 15.45%;
  bottom: 84.55%;
  border: 1px solid $secondary-color-7;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.popper-header {
  display: flex;
  justify-content: flex-start;
  padding: 5px 0;
}
.editHeaderpopup {
  width: 383px;
}
#customized-dialog-title {
  font-family: PoppinsBold, sans-serif;
  font-size: 1.125rem;
  line-height: 24px;
}
