@import "../Colors/color_variables.scss";

.header-h1 {
  font-family: PoppinsSemiBold, sans-serif !important ;
  font-size: 1.5rem !important;
  line-height: 36px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}
.header-h1-alert {
  font-family: PoppinsRegular, sans-serif !important ;
  font-size: 1rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}
.header-h1-primary {
  font-family: PoppinsBold, sans-serif !important ;
  font-size: 1.5rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}
.header-h1-sec {
  font-family: PoppinsSemiBold, sans-serif !important ;
  font-size: 1.25rem !important ;
  line-height: 20px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}
.headerForModel {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 21px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}

.header-h2 {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 1.25rem !important;
  line-height: 28px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-3 !important;
}
.header-h2-val {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 16px !important;
  letter-spacing: -0.2px !important;
  text-transform: initial !important;
  color: $primary-color-3 !important;
}
.header-h3 {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 1.125rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}
.header-h3-black {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 1.125rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-5 !important;
}
.header-h4 {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 1rem !important ;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}

.header-h5 {
  font-family: PoppinsRegular, sans-serif !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}
.header-h5-label {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important ;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-3 !important;
}
.header-h6 {
  font-family: PoppinsSemiBold, sans-serif !important;
  line-height: 36px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}
.speciality-header-h6 {
  line-height: 25px !important;
  text-align: end !important;
}
.content-body-text {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important ;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}
.content-body-text-sec {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 21px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}
.content-emphasized-text {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}
.content-emphasized-label {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 1.5rem !important;
  line-height: 36px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-2 !important;
}
.content-emphasized-text-button {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-4 !important;
}

.content-emphasized-light-text {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-2 !important;
}
.content-emphasized-tag-text {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 1.125rem !important;
  line-height: 21px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-26 !important;
}
.content-emphasized-tag1-text {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-26 !important;
}
.content-emphasized-light-text2 {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-5 !important;
}
.content-emphasized-light-chief-text2 {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-5 !important;
  display: block;
  width: 100%;
  word-break: break-all;
}

.content-emphasized-active-text {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  cursor: pointer;
  letter-spacing: -0.2px !important;
  text-decoration-line: underline !important;
  color: $primary-color-2 !important;
}

.content-emphasized-active-text2 {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  cursor: pointer;
  letter-spacing: -0.2px !important;
  color: $primary-color-2 !important;
}
.content-emphasized-non-active-text {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  cursor: pointer;
  letter-spacing: -0.2px !important;
  color: $secondary-color-23 !important;
}
.content-emphasized-disabled-text {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 17px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-23 !important;
}
.content-emphasized-light-text-link {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  letter-spacing: -0.2px !important;
  text-decoration-line: underline !important;
  color: $secondary-color-2 !important;
}

.content-emphasized-light-active-text {
  :active {
    font-family: PoppinsRegular, sans-serif !important;
    font-size: 0.875rem !important;
    line-height: 20px !important;
    letter-spacing: -0.2px !important;
    text-decoration-line: underline !important;
    color: $primary-color-1 !important;
  }
}

.content-link-text {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  text-decoration-line: underline !important;
  color: $primary-color-2 !important;
}

.content-emphasized-link-text {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  text-decoration-line: underline !important;
  color: $primary-color-2 !important;
}
.content-emphasized-sec-text {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-2 !important;
}
.content-form-label-text {
  font-family: PoppinsLight, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-2 !important;
}

.content-form-bottom-label-text {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.75rem !important;
  line-height: 16px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-1 !important;
}
.content-form-bottom-label-low-text {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.75rem !important;
  line-height: 16px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-28 !important;
}
.content-form-label-italic-text {
  font-family: PoppinsItalic, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-3 !important;
}

.content-label-disabled-text {
  :disabled {
    font-family: PoppinsRegular, sans-serif !important;
    font-size: 0.875rem !important;
    line-height: 20px !important;
    letter-spacing: -0.2px !important;
    color: $secondary-color-3 !important;
  }
}

.content-subtitle-text {
  font-family: PoppinsItalic, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-2 !important;
}

.content-placeholder-text {
  ::placeholder {
    font-family: PoppinsItalic, sans-serif !important;
    font-size: 0.875rem !important;
    line-height: 24px !important;
    letter-spacing: -0.2px !important;
    color: $secondary-color-3 !important;
  }
}

.content-invalid-text {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-9 !important;
}

.content-body-invalid-text {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-9 !important;
}

.content-samll-label-text {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}

.content-samll-label-link-text {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  text-decoration-line: underline !important;
  color: $primary-color-2 !important;
}

.content-samll-label-emphasized-text {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}

.content-label-emphasized-text {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-4 !important;
}
.content-label-emphasized {
  font-family: PoppinsMedium, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 21px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-4 !important;
}
.notification-btn-text {
  font-family: PoppinsMedium, sans-serif !important;
  font-size: 0.6255rem !important;
  line-height: 24px !important;
  color: $primary-color-2 !important;
}
.notification-content-text {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.75rem !important;
  line-height: 15px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-1 !important;
}
.notification-content-link-text {
  font-family: PoppinsMedium, sans-serif !important;
  font-size: 0.6255rem !important;
  line-height: 15px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-2 !important;
}
.notification-timestamp-text {
  font-family: PoppinsMedium, sans-serif !important;
  font-size: 0.6255rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-23 !important;
}

.content-label-subtitle-text {
  font-family: PoppinsItalic, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-2 !important;
}

.header-h2-light {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 1.125rem !important ;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-2 !important;
}

.header-h4-light {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 1rem !important ;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-2 !important;
}

.header-h4-link {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 1rem !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-2 !important;
}

.header-h4-link-active {
  :active {
    font-family: PoppinsSemiBold, sans-serif !important;
    font-size: 1rem !important ;
    line-height: 24px !important;
    letter-spacing: -0.2px !important;
    text-decoration-line: underline !important;
    color: $primary-color-2 !important;
  }
}
.header-h5-light {
  letter-spacing: -0.2px;
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 1.125rem !important ;
  line-height: 36px !important;
  color: $secondary-color-2 !important;
}
.header-h5-regular {
  letter-spacing: -0.2px;
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important ;
  line-height: 20px !important;
  color: $secondary-color-2 !important;
}
.header-h5-menuitemlist {
  letter-spacing: -0.2px;
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important ;
  line-height: 20px !important;
}
.captions-emphasis-1 {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 0.75rem !important;
  line-height: 16px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}

.captions-emphasis-2 {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.75rem !important ;
  line-height: 16px !important;
  color: $secondary-color-2 !important;
}

.chat-filter-text {
  font-family: PoppinsRegular, sans-serif !important ;
  font-size: 0.75rem !important ;
  line-height: 24px !important ;
  color: $secondary-color-2 !important;
  text-decoration-line: underline !important;
}

.captions-default {
  font-family: PoppinsRegular, sans-serif !important ;
  font-size: 0.75rem !important ;
  line-height: 16px !important ;
  color: $primary-color-1 !important;
}
.captions-default-error {
  font-family: PoppinsRegular, sans-serif !important ;
  font-size: 0.75rem !important ;
  line-height: 16px !important ;
  color: $secondary-color-9 !important;
}
.captions-default-med {
  font-family: PoppinsRegular, sans-serif !important ;
  font-size: 0.75rem !important ;
  line-height: 16px !important ;
  color: $secondary-color-27 !important;
}

.captions-default2 {
  font-family: PoppinsRegular, sans-serif !important ;
  font-size: 0.75rem !important ;
  line-height: 16px !important ;
  color: $primary-color-2 !important;
}

.captions-link {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.75rem !important;
  line-height: 16px !important;
  letter-spacing: -0.2px !important;
  text-decoration-line: underline !important;
  color: $primary-color-2 !important;
}

.captions-link-active {
  :active {
    font-family: PoppinsRegular, sans-serif !important;
    font-size: 0.75rem !important;
    line-height: 16px !important;
    letter-spacing: -0.2px !important;
    text-decoration-line: underline !important;
    color: $primary-color-2 !important;
  }
}

.captions-subtitle {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.75rem !important;
  line-height: 16px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-2 !important;
}

.captions-italic {
  font-family: PoppinsItalic, sans-serif !important;
  font-size: 0.75rem !important;
  line-height: 16px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-2 !important;
}

.content-emphasized-sidenav-text {
  font-family: Poppins, sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important ;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-8 !important;
}

.content-select-type-text {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-1 !important;
}
.captions-sub-default {
  font-family: PoppinsRegular, sans-serif !important ;
  font-size: 11px !important ;
  line-height: 13px !important ;
}
.captions-text-default {
  font-family: PoppinsSemiBold, sans-serif !important ;
  font-size: 0.875rem !important ;
  line-height: 17px !important ;
}
.captions-sub-text-default {
  font-family: PoppinsRegular, sans-serif !important ;
  font-size: 14px !important ;
  line-height: 17px !important ;
}
.captions-text-greyout {
  font-family: PoppinsRegular, sans-serif !important ;
  font-size: 1.125rem !important ;
  line-height: 22px !important ;
  color: $secondary-color-5 !important;
}
.captions-text-greyout-medium-primary {
  font-family: PoppinsMedium, sans-serif !important ;
  font-size: 0.875rem !important ;
  color: $secondary-color-22 !important;
}
.captions-btn-medium-primary {
  font-family: PoppinsSemiBold, sans-serif !important ;
  font-size: 1rem !important ;
  line-height: 24px;
  color: $secondary-color-22 !important;
}
.captions-text-greyout-medium-secondary {
  font-family: PoppinsMedium, sans-serif !important ;
  font-size: 0.875rem !important ;
  color: $primary-color-2 !important;
}
.add-btn-secondary {
  font-family: PoppinsRegular, sans-serif !important ;
  font-size: 0.875rem !important ;
  color: $secondary-color-22 !important;
}
.captions-chip-med {
  font-family: PoppinsSemiBold, sans-serif !important ;
  font-size: 0.6875rem !important ;
  line-height: 24px !important ;
  color: $secondary-color-24 !important;
}
.captions-chip-high {
  font-family: PoppinsSemiBold, sans-serif !important ;
  font-size: 0.6875rem !important ;
  line-height: 24px !important ;
  color: $secondary-color-25 !important;
}
.captions-chip-low {
  font-family: PoppinsSemiBold, sans-serif !important ;
  font-size: 0.6875rem !important ;
  line-height: 24px !important ;
  color: $secondary-color-28 !important;
}
.captions-chip-unknown {
  font-family: PoppinsSemiBold, sans-serif !important ;
  font-size: 0.6875rem !important ;
  line-height: 24px !important ;
  color: $secondary-color-1 !important;
}
.profile-header {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 0.75rem !important;
  line-height: 16px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-2 !important;
}
.profile-footer {
  font-family: SF Pro Text, sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  line-height: 14px !important;
  letter-spacing: -0.016em !important;
  color: $primary-color-1 !important;
}
.chatSection-PageView.importantClass {
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 0.625rem;
  line-height: 1.333;
  color: $secondary-color-2;
}

.chatSection-resChangeMenuOptions.importantClass {
  font-family: Poppins, sans-serif;
  font-weight: normal;
  font-size: 0.625rem;
  line-height: 1.3333;
  color: $primary-color-1;
}

.chatMessage-Name.importantClass {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.214;
  letter-spacing: -0.016em;
  color: $primary-color-4;
  white-space: pre-wrap;
  word-break: break-all;
}

.chatMessage-Name.importantClass.chatMessage-Role {
  font-weight: normal;
  font-size: 0.688rem;
  line-height: 1.18;
}

.chatMessage-Name.importantClass.chatMessage-Role.senderStyle,
.chatMessage-Name.importantClass.senderStyle {
  color: $primary-color-1;
}
.line-text-bold {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 1.125rem !important;
  line-height: 27px !important;
  color: $primary-color-1 !important;
}
.backto-consult {
  font-family: PoppinsSemiBold, sans-serif !important;
  font-size: 0.6875rem !important;
  line-height: 36px !important;
  letter-spacing: -0.2px !important;
  color: $primary-color-1 !important;
}
.chatMessage-Name.importantClass.senderStyle.textColorWhite {
  color: #fff;
}
.incompatibleHeader {
  font-family: PoppinsSemiBold;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: #000000 !important;
  padding: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.incompatibleHeader2 {
  font-size: 18px !important;
}
.incompatibleCloseIcon {
  float: right;
  width: 15px !important;
}
.incompatibleHr {
  border: 0.5px solid #ebecf0;
}
.incompatibleTitle {
  font-family: PoppinsRegular, sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.016em;
  color: #000000;
  padding: 10px 70px;
}
.incompatibleContent {
  font-family: PoppinsRegular, sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.016em;
  color: #000000;
  padding: 10px 50px;
}
.incompatibleButton {
  background-color: #9b51e0 !important;
  color: white !important;
  text-transform: initial !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  font-family: PoppinsRegular, sans-serif !important;
  font-style: normal;
  margin-right: 15px !important;
}
.item1 {
  grid-area: sideImage;
}
.item2 {
  grid-area: rightContent;
}
.item3 {
  grid-area: rightImages;
}

.gridContainer {
  display: grid;
  grid-template-areas:
    "sideImage rightContent"
    "sideImage rightImages";
  padding: 20px;
}

.gridContainer > div {
  text-align: center;
}
.mobileTitle {
  font-family: PoppinsRegular, sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.016em;
  color: #000000;
}
.mobileContent {
  font-family: PoppinsRegular, sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  letter-spacing: -0.016em;
  color: #000000;
  padding-left: 41px;
}
.qrcode {
  width: 80px;
  height: 80px;
}
.logoStore {
  text-align: left;
}

.qrcodeitem {
  grid-area: qrcode;
  padding-left: 10px;
}
.applelogoitem {
  grid-area: storelogo;
  text-align: left;
}
.playlogoitem {
  grid-area: storelogo;
  text-align: left;
}

.gridContainerChid {
  display: grid;
  grid-template-areas:
    "qrcode ."
    "qrcode storelogo";
}
