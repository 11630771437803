.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.file-drop-dragging-over-frame #dragAndDropContainer {
  z-index: 2;
  visibility: visible;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.file-drop,
.file-drop-target {
  height: 100%;
}
#arrow {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  visibility: hidden;
  &::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
    visibility: visible;
    content: "";
    transform: rotate(45deg);
  }
}
#tooltip[data-popper-placement^="top"] {
  > #arrow {
    bottom: -4px;
  }
}
#tooltip[data-popper-placement^="bottom"] {
  > #arrow {
    top: -4px;
  }
}
#tooltip[data-popper-placement^="left"] {
  > #arrow {
    right: -4px;
  }
}
#tooltip[data-popper-placement^="right"] {
  > #arrow {
    left: -4px;
  }
}
.Toastify__toast--warning {
  background: #9b51e0 !important;
}
.Toastify__toast--success {
  background: #9b51e0 !important;
}
.Toastify__toast--error {
  background: #9b51e0 !important;
}
